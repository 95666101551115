import { Component } from '@angular/core';
import { ApiService } from '../_services/api-service/api.service';
import { AcademicYearDto } from '../_models/academicYearDto';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectableKeyVal } from '../_models/key-val.select';
import { AdvancedSearchTerm } from '../_models/advancedSearchTerm';
import { CourseSearchResultsDto } from '../_models/courseSearchResultsDto';
import { CourseSearchQueryModel } from '../_models/courseSearchQueryModel';
import { PagingOptions } from '../_models/pagingOptions';
import {  PageEvent } from '@angular/material/paginator';
import { CourseSearchDto } from '../_models/courseSearchDto';
import { Sort } from '@angular/material/sort';
import { orderBy } from 'lodash';
import { LoggingService } from '@tdoe/design-system';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';

import dayjs from 'dayjs';
import { MatSelectChange } from '@angular/material/select';
import { AcademicYearPublishStates } from '../_models/academicYearPublishStates';
import { GradeDto } from '../_models/gradesDto';
import { EndorsementsDto } from '../_models/endorsementsDto';
import { CourseTypeDto } from '../_models/courseTypeDto';
import { AttributeDto } from '../_models/attributeDto';
import { ContentAreaDto } from '../_models/contentAreasDto';
import { DistrictDto } from '../_models/districtDto';
import { NoteTypeDto } from '../_models/noteTypeDto';
import { finalize, forkJoin, take } from 'rxjs';
import { LoadingService } from '../_services/loading-service/loading.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  academicYears: AcademicYearDto[] = [];
  advanced = false;
  courseQuery: CourseSearchQueryModel = {
    academicYearId: 0,
    basicSearchTerms: [''],
    changesOnly: false,
    pagingOptions: {
      pageSize: 10,
      pageNumber: 1,
      sortColumns: [{
        columnName: 'Name',
        direction: 'ASC'
      }]
    },
    minimumTermWeight: 1,
    isAdvancedSearch: true,
    advancedSearchTerms: [{
      fieldName: 'Description',
      attributeCode: undefined,
      operator: 'Contains',
      searchTerm: []
    }],
    isRetired: false
  };

  containOperators = [
    { name: 'Contains', value: 'Contain' },
    { name: 'Does not contain', value: 'Does not contain' },
    { name: 'Starts with', value: 'Starts with' },
    { name: 'Ends with', value: 'Ends with' }
  ];
  includesOperators = [
    { name: 'Includes', value: 'Include' },
    { name: 'Excludes', value: 'Exclude' },
  ];
  grades = Array<GradeDto>();
  endorsements = Array<EndorsementsDto>();
  courseTypes = Array<CourseTypeDto>();
  attributes = Array<AttributeDto>();
  contentAreas = Array<ContentAreaDto>();
  displayedColumns = [
    'courseCode',
    'publishState',
    'courseName',
    'retireStatus',
    'courseType',
    'contentArea',
    'previousCourseCode'
  ];
  expandedColumns = [
    ...this.displayedColumns,
    'expand'
  ];
  expandedCourse?: CourseSearchDto | null;
  lastPublishDate!: string;
  pages?: number[];
  pageSizeOptions: number[] = [5, 10, 25, 100];
  previousSearch!: CourseSearchQueryModel;
  results!: CourseSearchResultsDto;
  searchFields: SelectableKeyVal[] = [
    {
      label: 'Code',
      value: 'Code'
    },
    {
      label: 'Description',
      value: 'Description'
    },
    {
      label: 'Name',
      value: 'Name'
    },
    {
      label: 'Previous Code',
      value: 'PreviousCourseCode'
    }
  ];
  searchForm = this.fb.group({
    academicYear: [0],
    criteria: this.fb.array([
      this.createCriteria()
    ]),
    minTermWeight: [0],
    pagingOptions: this.fb.group({
      sortColumns: this.fb.array([
        this.fb.group({
          columnName: ['Name'],
          direction: 'ASC'
        })
      ]),
      pageSize: [10],
      pageNumber: [this.results?.currentPage ?? 1]
    }),
    query: [''],
    retired: [false],
    searchType: ['recent']
  });
  searchOperators: SelectableKeyVal[] = [
    {
      label: 'Contains',
      value: 'Contains'
    },
    {
      label: 'Does not contain',
      value: 'Does not contain'
    },
    {
      label: 'Starts with',
      value: 'Starts with'
    },
    {
      label: 'Ends with',
      value: 'Ends with'
    }
  ];
  searchType: 'all' | 'recent' = 'all';
  sortDir: Sort = {
    active: 'courseName',
    direction: 'asc'
  };

  isMasterDataLoaded = false;
  get criteria(): FormArray {
    return this.searchForm.get('criteria') as FormArray;
  }

  get currentAcademicYear(): AcademicYearDto | undefined {
    return this.academicYears.find(_ => _.academicYearId === this.currentYear);
  }

  get currentPage(): number {
    return this.pageIndex + 1;
  }

  get currentYear(): number {
    return this.searchForm.get('academicYear')?.value ?? 0;
  }

  get pageIndex(): number {
    return (this.searchForm.get('pagingOptions.pageNumber')?.value ?? 1) - 1;
  }

  get query(): string | null | undefined {
    return this.searchForm.get('query')?.value;
  }

  get sortColumn(): FormControl {
    return this.sortOptions.get('columnName') as FormControl;
  }

  get sortDirection(): FormControl {
    return this.sortOptions.get('direction') as FormControl;
  }

  get sortOptions(): FormGroup {
    return (this.searchForm.get('pagingOptions.sortColumns') as FormArray).at(0) as FormGroup;
  }

  get pageSize(): number | any {
    if (this.advanced) {
      return this.courseQuery.pagingOptions.pageSize;
    } else {
      return this.searchForm.get('pagingOptions.pageSize')?.value;
    }
  }

  constructor(private apiService: ApiService,
    private fb: FormBuilder,
    private logger: LoggingService,
    private loadingService: LoadingService,
    router: Router) {
    const state = router.getCurrentNavigation()?.extras.state;
    if (state) {
      const savedState = state['savedState'];
      if (savedState) {
        this.academicYears = savedState['academicYears'];
        this.lastPublishDate = savedState['lastPublishDate'];
        this.results = savedState['results'];
        this.mapResult();
        this.searchForm.patchValue(savedState['searchForm']);
        this.logger.debug('home.component.ctor', {
          title: 'event',
          data: {
            state
          }
        });
      }
    }
  }

  addCriteria(): void {
    this.criteria.push(this.createCriteria());
  }

  createCriteria(searchTerm?: AdvancedSearchTerm): FormGroup {
    return this.fb.group({
      field: [searchTerm?.fieldName ?? 'Description'],
      operator: [searchTerm?.operator ?? 'Contains'],
      term: [searchTerm?.searchTerm && searchTerm.searchTerm.length > 0 ? searchTerm.searchTerm[0] : undefined]
    });
  }

  async export(format: string): Promise<void> {
    const file = await this.apiService.export(this.previousSearch, format);

    let ext = '';
    let mime = '';
    let filename = `TDOECourses_Published${dayjs().format('YYYYMMDD_HHmmss')}`;

    switch (format) {
      case 'json':
        mime = 'application/json';
        ext = 'json';
        break;
      case 'csv':
        mime = 'text/csv';
        ext = 'csv';
        break;
      case 'report':
        filename = 'CourseChangeReportExternal';
        mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        ext = 'xlsx';
        break;
      case 'excel':
        mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        ext = 'xlsx';
        break;
      case 'pipe':
        mime = 'text/plain;charset=utf-8';
        ext = 'csv';
        break;
    }

    const blob = new Blob([file], { type: mime });
    saveAs(blob, `${filename}.${ext}`);
  }


  manageRow(index: number) {
    const termToUpdate = this.courseQuery && this.courseQuery.advancedSearchTerms && this.courseQuery.advancedSearchTerms[index];
    if (termToUpdate) {
      termToUpdate.searchTerm = [];
      termToUpdate.attributeCode = undefined;
      switch (termToUpdate.fieldName) {
        case 'Description':
        case 'Name':
        case 'Code':
        case 'PreviousCourseCode':
          termToUpdate.operator = 'Contains';
          break;
        case 'Type':
          termToUpdate.operator = 'Include';
          if (this.courseTypes.length > 0) {
            termToUpdate.searchTerm.push(this.courseTypes[0].systemCode);
          }
          break;
        case 'Grades':
          termToUpdate.operator = 'Include';
          if (this.grades.length > 0) {
            termToUpdate.searchTerm.push(this.grades[0].systemCode);
          }
          break;
        case 'ContentArea':
          termToUpdate.operator = 'Include';
          if (this.contentAreas.length > 0) {
            const t = this.contentAreas[0].systemCode;
            termToUpdate.searchTerm.push(t);
          }
          break;
        case 'Endorsements':
          termToUpdate.operator = 'Include';
          if (this.grades.length > 0) {
            const t = this.endorsements[0].systemCode;
            termToUpdate.searchTerm.push(t);
          }
          break;
        case 'Attributes':
          termToUpdate.operator = 'Include';
          if (this.attributes.length > 0) {
            const att = this.attributes[0];
            const terms = this.setDefaultAttributeSearchTerm(termToUpdate, att.systemCode);
          }
          break;
      }
    }

  }

  setDefaultAttributeSearchTerm(term: AdvancedSearchTerm, code?: string) {
    term.searchTerm = [];
    const att = this.attributes.find(x => x.systemCode === code);
    if (att) {
      if (att && att.controlType === 'checkbox') {
        term.searchTerm.push('true');
        return;
      }
      const propValues = this.getAttributeValues(att.systemCode);
      term.attributeCode = att.systemCode;
      if (propValues) {
        term.searchTerm.push(propValues[0].value);
        return;
      }
      term.searchTerm.push('');
    }

    return;
  }


  getAttributeValues(attributeCode: string | undefined) {
    const attribute = this.attributes.find(x => x.systemCode === attributeCode);
    if (attribute && attribute.controlType === 'dropdown') {
      return attribute.properties.dropdownOptions;
    }
    else if (attribute && attribute.controlType === 'multiselect') {
      return attribute.properties.dropdownOptions;
    }
    return null;
  }

  extractSearchDto(): CourseSearchQueryModel {
    const result: CourseSearchQueryModel = {
      academicYearId: this.searchForm.get('academicYear')?.value ?? 0,
      pagingOptions: this.searchForm.get('pagingOptions')?.value as PagingOptions,
      changesOnly: this.searchForm.get('searchType')?.value !== 'all',
      isAdvancedSearch: this.advanced,
      minimumTermWeight: this.searchForm.get('minTermWeight')?.value ?? 0,
      academicYearCode: this.currentAcademicYear?.systemCode

    };

    if (this.advanced) {
      result.advancedSearchTerms = [];
      this.criteria.controls.forEach(_ => {
        result.advancedSearchTerms?.push(
          {
            fieldName: _.get('field')?.value ?? '',
            operator: _.get('operator')?.value ?? '',
            searchTerm: [_.get('term')?.value]
          }
        );
      });

      result.isRetired = this.searchForm.get('retired')?.value ?? false
    } else {
      result.basicSearchTerms = [this.query ?? ''];
    }

    return result;
  }

  getGrades(course: CourseSearchDto): string {
    if (!course?.grades) return 'N/A';
    const result = course.grades.map(c => c.name).join(', ');
    this.logger.debug('home.component', {
      title: 'event',
      data: {
        result,
        course
      }
    });
    return result;
  }

  async goToPage(e: MatSelectChange): Promise<void> {
    this.searchForm.patchValue({
      pagingOptions: {
        pageNumber: e.value
      }
    });
    this.courseQuery.pagingOptions.pageNumber = e.value;
    await this.search(false);
  }

  async highlight(): Promise<void> {
    const newSearch = this.extractSearchDto();
    delete newSearch.basicSearchTerms;
    newSearch.academicYearCode = this.currentAcademicYear?.systemCode;
    this.results = await this.apiService.search(newSearch);
    this.mapResult();
    this.previousSearch = newSearch;
  }

  isRetired(course: CourseSearchDto): boolean {

    if (!course.retireDate) return false;

    try {
      const retireDate = dayjs(course.retireDate);
      if (retireDate <= dayjs()) return true;
    } catch (exc: any) {
      this.logger.error('home.isRetired', {
        title: 'Parse error',
        data: {

        }
      });
    }
    return false;
  }

  async ngOnInit(): Promise<void> {
    this.tabChanged(0);

    this.logger.debug('home.component.ngOnInit', {
      title: 'event',
      data: {
        _this: this
      }
    });

    if (this.academicYears.length === 0) {
      this.academicYears = await this.apiService.listAcademicYears();
      const currentYear = this.academicYears.reduce((cy, ay) => Math.max(cy, ay.academicYearId ?? 0), 0);
      this.searchForm.patchValue({
        academicYear: currentYear
      });
      const result = await this.apiService.getLastPublishDate(currentYear);
      this.lastPublishDate = result?.result;
    }

    this.searchForm.get('searchType')?.valueChanges.subscribe(_ => {
      if (_ === 'recent') {
        this.searchForm.patchValue({
          minTermWeight: 1
        }, { emitEvent: true });
        this.highlight();
      } else {
        this.searchForm.patchValue({
          minTermWeight: 0
        }, { emitEvent: true });
      }
    });

    this.highlight();

    this.searchForm.get('academicYear')?.valueChanges.subscribe(currentYear => {
      if (this.advanced) {
        this.getMasterData(currentYear ?? 0);
      }
    });
  }

  removeRow(index: number): void {
    this.courseQuery.advancedSearchTerms?.splice(index, 1);
  }

  getMasterData(academicYear: number): void {
    const getGrades = this.apiService.getAllGrades(academicYear);
    const getCourseTypes = this.apiService.getCourseTypes(academicYear);
    const getAttributes = this.apiService.getAttributes(academicYear);
    const getEndorsements = this.apiService.getEndorsements(academicYear);
    const getContentAreas = this.apiService.getAllContentAreas(academicYear);
    const spintId = this.loadingService.uniqueSpinId;
    this.loadingService.show(spintId);
    forkJoin([getGrades, getCourseTypes, getAttributes, getEndorsements, getContentAreas])
      .pipe(take(1),
        finalize(() => this.loadingService.hide(spintId))
      ).subscribe(results => {
        this.grades = results[0];
        this.courseTypes = results[1];
        this.attributes = results[2];
        this.endorsements = results[3];
        this.contentAreas = results[4];
        this.isMasterDataLoaded = true;
      });
  }

  showIncludes(fieldName: string | undefined, attributeCode: string | undefined) {
    const ct = this.getAttributeControlType(attributeCode);
    switch (fieldName) {
      case 'Type':
      case 'Grades':
      case 'Endorsements':
      case 'PublishState':
      case 'ContentArea':
      case 'Notes':
      case 'Districts':
        return true;
      case 'Attributes':
        switch (ct) {
          case 'checkbox':
          case 'multiselect':
          case 'dropdown':
            return true;
          default:
            return false;
        }
      default:
        return false;
    }
  }


  showContains(fieldName: string | undefined, attributeCode: string | undefined) {
    const ct = this.getAttributeControlType(attributeCode);
    switch (fieldName) {
      case 'Type':
      case 'Grades':
      case 'Endorsements':
      case 'PublishState':
      case 'ContentArea':
      case 'Notes':
      case 'Districts':
        return false;
      case 'Attributes':
        switch (ct) {
          case 'checkbox':
          case 'multiselect':
          case 'dropdown':
            return false;
          default:
            return true;
        }
      default:
        return true;
    }
  }

  getAttributeDisplayLabel(attributeCode: string | undefined) {
    const attribute = this.attributes.find(x => x.systemCode === attributeCode);
    if (attribute) {
      return attribute.displayLabel;
    }
    return null;
  }

  getAttribute(attributeCode: string | undefined) {
    const attribute = this.attributes.find(x => x.systemCode === attributeCode);
    return attribute;
  }

  flipCheckBoxAttribute(term: any) {
    term.searchTerm[0] = (term.searchTerm[0] !== 'true').toString();
  }

  getAttributeControlType(code: string | undefined): string {
    const attr = this.attributes.find(function (a) {
      return a.systemCode === code;
    });
    if (attr) {
      return attr.controlType;
    } else {
      return '';
    }
  }

  addAdvancedRow() {
    this.courseQuery.advancedSearchTerms?.push({
      fieldName: 'Description',
      attributeCode: null,
      operator: 'Contains',
      searchTerm: []
    } as any);
  }

  onSortChange(sortState: Sort) {
    this.sortDir = sortState;
    this.results.results = orderBy(this.results.results, [sortState.active], [sortState.direction as 'asc' | 'desc']) as CourseSearchDto[];
  }

  async paginationChanged(e: any): Promise<void> {
    this.searchForm.get('pagingOptions.pageSize')?.setValue(e.pageSize);
    this.searchForm.get('pagingOptions.pageNumber')?.setValue(e.pageIndex + 1);
    this.courseQuery.pagingOptions.pageNumber = e.pageIndex + 1;
    this.courseQuery.pagingOptions.pageSize = e.pageSize;
    await this.search(false);
  }

  removeCriteria(index: number): void {
    this.criteria.removeAt(index, { emitEvent: true });

  }

  async search(reset: boolean): Promise<void> {
    if (this.searchForm.invalid) {
      return;
    } else {
      if (reset) {
        this.searchForm.get('pagingOptions.pageNumber')?.setValue(1);
        this.courseQuery.pagingOptions.pageNumber = 1;
        this.courseQuery.pagingOptions.pageSize = 10;
      }
      if (this.advanced) {
        this.courseQuery.academicYearId = this.searchForm.get('academicYear')?.value ?? 0;
        this.courseQuery.academicYearCode = this.currentAcademicYear?.systemCode;
        const qm = JSON.parse(JSON.stringify(this.courseQuery)) as CourseSearchQueryModel;
        if (qm && qm.advancedSearchTerms) {
          qm.advancedSearchTerms.forEach(function (item, index, object) {
            if (item.searchTerm.length === 0) {
              object.splice(index, 1);
            }
          });
          if (qm.isAdvancedSearch === true && qm.advancedSearchTerms.length === 0 && qm.isRetired === false) {
            qm.isAdvancedSearch = false;
          }
        }
        this.results = await this.apiService.search(qm);
        this.previousSearch = qm;
      } else {
        const newSearch = this.extractSearchDto();
        this.results = await this.apiService.search(newSearch);
        this.previousSearch = newSearch;
      }
      this.mapResult();
      if (this.results.pageCount > 0) {
        const pages = [];
        for (let i = 0; i < this.results.pageCount; i++) {
          pages.push(i + 1);
        }
        this.pages = pages;
      } else {
        delete this.pages;
      }
    }
  }

  selectedField(index: number): SelectableKeyVal {
    const val: string = (this.searchForm.get('criteria') as FormArray).at(index).get('field')?.value;
    return this.searchFields.find(_ => _.value === val) ?? this.searchFields[2];
  }

  status(course: CourseSearchDto): string {
    if (this.isRetired(course)) return 'Retired';
    return course.publishState;
  }

  tabChanged(e: number): void {
    this.advanced = e === 1;
    if (this.advanced) {
      this.courseQuery.pagingOptions.pageSize = 10;
      this.searchForm.get('pagingOptions.pageSize')?.setValue(10);
      this.searchForm.get('query')?.disable();
      this.criteria.enable();
      if (!this.isMasterDataLoaded) {
        this.getMasterData(this.searchForm.get('academicYear')?.value as any);
      }
    } else {
      this.searchForm.get('query')?.enable();
      this.criteria.disable();
    }
    this.searchForm.get('query')?.updateValueAndValidity();
    this.criteria.updateValueAndValidity();

  }

  toggle(e: MouseEvent, course: CourseSearchDto): void {
    e.stopPropagation();
    if (this.expandedCourse === course) {
      this.expandedCourse = null;
    } else {
      this.expandedCourse = course;
    }
  }

  private mapResult() {
    if (this.results && this.results.results && this.results.results.length) {
      this.results.results = this.results.results.map(x => ({
        ...x, retireStatus: (() => {
          if (x.retireDate) {
            return new Date(x.retireDate).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0) ? 'Retiring Soon' : 'Retired';
          }
          return '';
        })()
      }))
    }
  }

}
