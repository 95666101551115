import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { provideGlobalErrorHandling, provideLoadingInterceptor, provideMSAL, TdoeAuthConfig, TdoeDsModule, TdoeToastComponent } from '@tdoe/design-system';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NoopAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { CourseDetailComponent } from './course-detail/course-detail.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TimeagoModule } from 'ngx-timeago';
import { ToastrModule } from 'ngx-toastr';

import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { LoadingInterceptor } from './loading.interceptor';
import { TdoeCardComponent,TdoeLayoutComponent } from '@tdoe/design-system';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CourseDetailComponent
  ],
  bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserModule,
    TdoeDsModule,
    TdoeCardComponent,
    TdoeLayoutComponent,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'course/:courseCode/:courseId/:academicYearId', component: CourseDetailComponent }
    ], {
      scrollPositionRestoration: 'top'
    }),
    NoopAnimationsModule,
    MsalModule,
    NgxSpinnerModule,
    TimeagoModule.forRoot(),
    ToastrModule.forRoot({
      toastComponent: TdoeToastComponent
    }),
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatSelectModule,
    MatSortModule], providers: [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LoadingInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
      },
      {
        provide: LocationStrategy,
        useClass: HashLocationStrategy
      },
      provideAnimations(),
      provideHttpClient(withInterceptorsFromDi()),
      provideMSAL(environment.authConfig),
    ]
})

export class AppModule { }
